import { Switch } from '@headlessui/react';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { classNames } from '../utils/presentation';

export default function CancellableToggle() {
  //   const [enabled, _setEnabled] = useState(true);
  const { setValue, watch } = useFormContext();

  const enabled = watch("can_cancel", true);

  const setEnabled = (enabled: boolean) => {
    setValue("can_cancel", enabled);
  };

  useEffect(() => {
    setEnabled(true);
  }, []);

  return (
    <Switch
      checked={enabled}
      onChange={setEnabled}
      className="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none"
    >
      <span className="sr-only">can cancel</span>
      <span aria-hidden="true" className="pointer-events-none absolute h-full w-full rounded-md bg-white" />
      <span
        aria-hidden="true"
        className={classNames(
          enabled ? "bg-primary" : "bg-gray-200",
          "pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out"
        )}
      />
      <span
        aria-hidden="true"
        className={classNames(
          enabled ? "translate-x-5" : "translate-x-0",
          "pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out"
        )}
      />
    </Switch>
  );
}
