import "react-datepicker/dist/react-datepicker.css";

import { useAuth } from "@elrond-giants/erd-react-hooks/dist";
import { ITransactionProps } from "@elrond-giants/erd-react-hooks/dist/types";
import { Dialog, Transition } from "@headlessui/react";
import { QuestionMarkCircleIcon } from "@heroicons/react/20/solid";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { joiResolver } from "@hookform/resolvers/joi";
import {
  Address,
  AddressValue,
  BigUIntValue,
  BooleanValue,
  BytesValue,
  ContractFunction,
  TokenPayment,
  TransactionPayload,
  U64Value,
} from "@multiversx/sdk-core/out";
import axios from "axios";
import Joi from "joi";
import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { FormProvider, useForm } from "react-hook-form";
import { Tooltip } from "react-tooltip";

import { appEnv, contractAddress, network } from "../config";
import { useAppDispatch, useAppSelector } from "../hooks/useStore";
import { useTransaction } from "../hooks/useTransaction";
import { togglePanel } from "../redux/slices/createStreamSlice";
import { ICreateStream } from "../types/SmartContract";
import { denominate } from "../utils/presentation";
import CancellableToggle from "./CancellableToggle";
import TokenSelect from "./TokenSelect";

const filterStartDate = (date: Date): boolean => {
  const currentDate = moment().startOf("day").toDate();
  return date >= currentDate;
};

const filterPassedTime = (time: any) => {
  const currentDate = new Date();
  const selectedDate = new Date(time);

  return currentDate.getTime() < selectedDate.getTime();
};

const generateTokenTransaction = (stream: ICreateStream, decimals: number): ITransactionProps => {
  const payload = TransactionPayload.contractCall()
    .setFunction(new ContractFunction("ESDTTransfer"))
    .addArg(new BytesValue(Buffer.from(stream.payment_token as string, "utf-8")))
    .addArg(
      new BigUIntValue(TokenPayment.fungibleFromAmount(stream.payment_token, stream.payment_amount, decimals).valueOf())
    )
    .addArg(new BytesValue(Buffer.from("createStream", "utf-8")))
    .addArg(new AddressValue(new Address(stream.recipient)))
    .addArg(new U64Value(moment(stream.start_time).unix()))
    .addArg(new U64Value(moment(stream.end_time).unix()))
    .addArg(new BooleanValue(stream.can_cancel))
    .build();

  return {
    receiver: contractAddress,
    data: payload.toString(),
    value: 0,
    gasLimit: 6_000_000,
  };
};

const generateEgldTransaction = (stream: ICreateStream): ITransactionProps => {
  const payload = TransactionPayload.contractCall()
    .setFunction(new ContractFunction("createStream"))
    .addArg(new AddressValue(new Address(stream.recipient)))
    .addArg(new U64Value(moment(stream.start_time).unix()))
    .addArg(new U64Value(moment(stream.end_time).unix()))
    .addArg(new BooleanValue(stream.can_cancel))
    .build();

  return {
    receiver: contractAddress,
    data: payload.toString(),
    value: stream.payment_amount,
    gasLimit: 6_000_000,
  };
};

export default function CreateStream() {
  const open = useAppSelector((state) => state.createStreamPanel.value);
  const dispatch = useAppDispatch();
  const { address, balance: egldBalance } = useAuth();
  const { makeTransaction } = useTransaction();
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const [balance, setBalance] = useState<string>();
  const [balanceLong, setBalanceLong] = useState<string>();
  const [decimals, setDecimals] = useState<number>();
  const [loading, setLoading] = useState(false);

  const schema = Joi.object<ICreateStream>({
    recipient: Joi.string()
      .pattern(/^erd1[a-z0-9]{58}/)
      .custom((data, helper) => {
        // @ts-ignore
        if (data === address) return helper.message("You can't stream towards yourself");

        return data;
      })
      .required(),
    payment_token: Joi.string()
      .pattern(/[A-Z]+(-[a-z0-9]+)?/)
      .required(),
    payment_amount: Joi.number().positive().required(),
    start_time: Joi.date().greater("now").required(),
    end_time: Joi.date().greater(Joi.ref("start_time")).required(),
    can_cancel: Joi.boolean(),
  });

  const getTokenBalanceByAddress = async (address: string, tokenIdentifier: string) => {
    if (tokenIdentifier === "EGLD") {
      return {
        balance: egldBalance,
        decimals: 18,
      };
    }
    try {
      const {
        data: { balance, decimals },
      } = await axios.get(`${network.apiAddress}/accounts/${address}/tokens/${tokenIdentifier}`);

      return { balance, decimals };
    } catch (e) {
      return { balance: "0", decimals: 0 };
    }
  };

  const methods = useForm<ICreateStream>({
    resolver: joiResolver(schema),
    defaultValues: {
      can_cancel: true,
    },
  });
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = methods;

  const watchToken = watch("payment_token");
  useEffect(() => {
    if (!watchToken) return;
    if (!address) return;
    (async () => {
      const balance = await getTokenBalanceByAddress(address, watchToken);
      setBalance(denominate(balance.balance, balance?.decimals).toFixed(2));
      setBalanceLong(denominate(balance.balance, balance?.decimals).toFixed(balance?.decimals));
      setDecimals(balance.decimals);
    })();
  }, [watchToken, address]);

  const onSubmit = (data: ICreateStream) => {
    createStream(data);
  };

  useEffect(() => {
    if (!startDate) return;
    setValue("start_time", moment(startDate).add(10, "minute").toDate());
  }, [startDate]);

  useEffect(() => {
    if (!endDate) return;
    setValue("end_time", moment(endDate).add(10, "minute").toDate());
  }, [endDate]);

  const createStream = async (stream: ICreateStream) => {
    if (stream.payment_amount > parseFloat(balance as string)) {
      alert(`You don't have ${stream.payment_amount} ${stream.payment_token} in your wallet.`);
      return;
    }
    setLoading(true);

    try {
      const transaction =
        stream.payment_token === "EGLD"
          ? generateEgldTransaction(stream)
          : generateTokenTransaction(stream, decimals as number);
      const txResult = await makeTransaction(transaction);

      if (txResult.status === "success") {
        // dispatch(fetchStreams({ address: address as string }));
        setTimeout(() => {
          dispatch(togglePanel({ open: false }));
          setStartDate(undefined);
          setEndDate(undefined);
          reset({ can_cancel: true });
        }, 2000);
      }
    } finally {
      setLoading(false);
    }
  };

  const togglePopup = (open: boolean) => {
    dispatch(togglePanel({ open }));
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={togglePopup}>
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden bg-black bg-opacity-50 ">
          <div className="absolute inset-0 overflow-hidden ">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16 ">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md ">
                  <FormProvider {...methods}>
                    <form
                      className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl rounded-l-3xl"
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <div className="h-0 flex-1 overflow-y-auto">
                        <div className="bg-primary py-6 px-4 sm:px-6 rounded-tl-3xl">
                          <div className="flex items-center justify-between">
                            <Dialog.Title className="font-semibold text-light-blue text-2xl">
                              Create Stream
                            </Dialog.Title>
                            <div className="ml-3 flex h-7 items-center">
                              <button
                                type="button"
                                className="rounded-md text-white focus:outline-none focus:ring-2 focus:ring-white"
                                onClick={() => togglePopup(false)}
                              >
                                <span className="sr-only">Close panel</span>
                                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                              </button>
                            </div>
                          </div>
                          <div className="mt-1">
                            <p className="text-white text-sm">Start streaming your ESDT in minutes.</p>
                          </div>
                        </div>
                        <div className="flex flex-1 flex-col justify-between">
                          <div className="divide-y divide-gray-200 px-4 sm:px-6">
                            <div className="space-y-6 pt-6 pb-5">
                              <div>
                                <label className="block text-xs font-semibold text-secondary uppercase">
                                  Recipient address
                                </label>
                                <div className="mt-1">
                                  <input
                                    type="text"
                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary focus:ring-secondary sm:text-sm"
                                    {...register("recipient")}
                                  />
                                  {errors["recipient"] && (
                                    <p className="mt-1 text-xs text-red-500">{errors["recipient"].message}</p>
                                  )}
                                </div>
                              </div>
                              <div>
                                <div className="">
                                  {/* <input
                                  type="text"
                                  readOnly
                                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary focus:ring-secondary sm:text-sm read-only:bg-gray-200 read-only:cursor-not-allowed"
                                  {...register("payment_token")}
                                /> */}
                                  <TokenSelect />
                                  {appEnv === "devnet" && (
                                    <p className="text-xs text-gray-500 mt-1">
                                      * Use the{" "}
                                      <a
                                        href="https://r3d4.fr/faucet"
                                        target="_blank"
                                        className="underline"
                                        rel="noreferrer"
                                      >
                                        Faucet
                                      </a>{" "}
                                      to get {process.env.NEXT_PUBLIC_ALLOWED_TOKEN}.
                                    </p>
                                  )}
                                  {errors["payment_token"] && (
                                    <p className="mt-1 text-xs text-red-500">{errors["payment_token"].message}</p>
                                  )}
                                </div>
                              </div>
                              <div>
                                <label className="block text-xs font-semibold text-secondary uppercase">Amount</label>
                                <div className="mt-1">
                                  <input
                                    type="number"
                                    step="0.001"
                                    placeholder="1000.56"
                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary focus:ring-secondary sm:text-sm"
                                    {...register("payment_amount")}
                                  />
                                  {balance && (
                                    <>
                                      <p
                                        className="text-xs text-gray-500 mt-1"
                                        data-tooltip-id="balance-tooltip"
                                        data-tooltip-content={`${balanceLong} ${watchToken}`}
                                      >
                                        Balance: {balance} {watchToken}
                                      </p>
                                      <Tooltip id="balance-tooltip" className="custom-tooltip" />
                                    </>
                                  )}
                                  {errors["payment_amount"] && (
                                    <p className="mt-1 text-xs text-red-500">{errors["payment_amount"].message}</p>
                                  )}
                                </div>
                              </div>
                              <div>
                                <label className="block text-xs font-semibold text-secondary uppercase">
                                  Start date
                                </label>
                                <div className="mt-1">
                                  <DatePicker
                                    selected={startDate}
                                    onChange={(date) => date && setStartDate(date)}
                                    showTimeSelect
                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary focus:ring-secondary sm:text-sm"
                                    filterTime={filterPassedTime}
                                    filterDate={filterStartDate}
                                    placeholderText="Select start date"
                                    dateFormat="MMMM d, yyyy h:mm aa"
                                  />
                                  {errors["start_time"] && (
                                    <p className="mt-1 text-xs text-red-500">{errors["start_time"].message}</p>
                                  )}
                                </div>
                              </div>
                              <div>
                                <label className="block text-xs font-semibold text-secondary uppercase">End date</label>
                                <div className="mt-1">
                                  <DatePicker
                                    selected={endDate}
                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary focus:ring-secondary sm:text-sm"
                                    onChange={(date) => date && setEndDate(date)}
                                    showTimeSelect
                                    filterTime={filterPassedTime}
                                    filterDate={filterStartDate}
                                    placeholderText="Select end date"
                                    dateFormat="MMMM d, yyyy h:mm aa"
                                  />
                                  {errors["end_time"] && (
                                    <p className="mt-1 text-xs text-red-500">{errors["end_time"].message}</p>
                                  )}
                                </div>
                              </div>
                              <div className="flex items-center justify-between">
                                <label className="block text-xs font-semibold text-secondary uppercase">
                                  can cancel
                                </label>
                                <div className="mt-1 flex items-center space-x-4">
                                  <CancellableToggle />
                                  <a
                                    href="https://docs.coindrip.finance/faq/basics#can-i-cancel-a-stream"
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <QuestionMarkCircleIcon className="h-5 w-5 text-primary hover:opacity-90" />
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div className="pt-4 pb-6">
                              <div className="mt-4 flex text-sm">
                                <a
                                  href="https://docs.coindrip.finance/faq/basics"
                                  target="_blank"
                                  className="group inline-flex items-center text-primary  hover:underline"
                                  rel="noreferrer"
                                >
                                  <QuestionMarkCircleIcon className="h-5 w-5 text-primary" aria-hidden="true" />
                                  <span className="ml-2">Learn more about token streams</span>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-shrink-0 justify-end px-4 py-4 bg-primary rounded-bl-3xl">
                        <button
                          type="button"
                          className="rounded-full bg-white py-2 px-4 text-sm font-semibold text-secondary shadow-sm hover:bg-gray-100 focus:outline-none uppercase"
                          onClick={() => togglePopup(false)}
                        >
                          Cancel
                        </button>
                        <button
                          type="submit"
                          className="hover:opacity-90 ml-4 inline-flex justify-center rounded-full  bg-light-blue py-2 px-4 text-sm font-semibold text-secondary shadow-sm focus:outline-none disabled:cursor-not-allowed disabled:opacity-50 uppercase"
                          disabled={loading}
                        >
                          Create Stream
                        </button>
                      </div>
                    </form>
                  </FormProvider>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
